import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-picker"
export default class extends Controller {
  connect() {
    console.log("Date Picker")

    
      $('#datetime').daterangepicker({
        autoUpdateInput: true,
        singleDatePicker: true,
        timePicker: true,
        "autoApply": true,
        opens: "right",
        "timePicker24Hour": true,
        timePickerIncrement: 15,
        locale: {
          format: "YYYY-MM-DD HH:mm"
        }
      });
    
  //    $('#datetime').on('apply.daterangepicker', function(ev, picker) {
//           console.log('Hello CLive');
    //  });
    

  }
}
